import React, { useEffect, useState } from 'react';
import CustomInput from '../../../../components/customInput';
import CustomDropdown from '../../../../components/customDropdown';
import { roles } from '../../../../utils/user/permissions';
import utils from '../../../../utils';
import CustomButton from '../../../../components/customButton';
import ActionButtons from '../../../../components/actionButtons';
import UserActionButtons from './userActionButtons';

const NewUsers = ({ siteGroup, updateSiteGroup }) => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState(roles[0]);
  const [users, setUsers] = useState(siteGroup && siteGroup.users ? siteGroup.users : []);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [usedEmails, setUsedEmails] = useState([]);

  useEffect(() => {
    roles.forEach(role => {
      role.renderName = 'Can View';
      role.action = 'view';
      if (role.name === 'Dashboard User') {
        role.renderName = 'Can Edit';
        role.action = 'edit';
      }
    });
    setUsedEmails(users.map(user => user.email));
  }, []);

  useEffect(() => {
    const validation = {
      'invite-email': [
        { key: 'required', value: true },
        { key: 'email', value: true },
        { key: 'unique', value: usedEmails, customMessage: 'Email is already being used!' }
      ]
    };

    const errorList = {
      'invite-email': utils.fieldValidation(email, validation['invite-email']).join(', ')
    };
    setErrors(errorList);
  }, [email]);

  useEffect(() => {
    updateSiteGroup({ ...siteGroup, users });
  }, [users]);

  const addUser = userToAdd => {
    if (!errors['invite-email']) {
      const usersList = [...users, userToAdd];
      setUsers(usersList);
      setUsedEmails(usersList.map(user => user.email));
    }
  };

  const removeUser = email => {
    const usersList = users.filter(user => user.email.toLowerCase() !== email.toLowerCase());
    setUsers(usersList);
    setUsedEmails(usersList.map(user => user.email));
  };

  const editUserRole = (action, user) => {
    if (user.role.action !== action) {
      const localUsers = utils.deepClone(users);
      const newRole = roles.find(role => role.action === action);
      localUsers.forEach(originalUser => {
        if (originalUser.email === user.email) {
          originalUser.role = newRole;
        }
      });
      setUsers(localUsers);
    }
  };

  const renderUserList = () => {
    return users.map(user => {
      return (
        <div className="user-row" key={user.email}>
          <div className="user-info">
            <label className="role">({user.role.name})</label>
            <span className="email">{user.email}</span>
          </div>
          <div className={'action-buttons'}>
            {showRoleModal === user && (
              <UserActionButtons
                buttons={[
                  { key: 'edit', title: 'Can edit', onPress: () => editUserRole('edit', user) },
                  { key: 'view', title: 'Can view', onPress: () => editUserRole('view', user) }
                ]}
              />
            )}
            <ActionButtons
              buttons={[
                {
                  id: `edit-role`,
                  icon: 'key',
                  key: 'Edit',
                  tooltip: 'Edit role',
                  onPress: () => {
                    setShowRoleModal(showRoleModal ? false : user);
                  }
                },
                {
                  id: `remove-user`,
                  icon: 'removeUser',
                  key: 'Remove',
                  tooltip: 'Remove User',
                  onPress: () => removeUser(user.email)
                }
              ]}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="new-users">
      <div className="new-users-header">
        <div className="email">
          <CustomInput
            placeholder={'Type an email to add'}
            id="invite-email"
            type="text"
            value={email}
            isEditable={true}
            autoComplete={'email'}
            onChange={e => {
              setEmail(e.target.value);
            }}
            errorMessage={errors['invite-email']}
          />
        </div>
        <div className="role">
          <CustomDropdown
            label={'Set a role'}
            items={roles.filter(item => item.id !== 'admobilize.owner')}
            displayField="renderName"
            valueField="id"
            selectedItem={roles.find(innerRole => innerRole.id === role.id)}
            handleSelection={event => setRole(roles.find(innerRole => innerRole.id === event.target.value))}
          />
        </div>
        <div className="action-buttons">
          <CustomButton
            title="Add"
            classes="btn btn-primary"
            handleClick={() => {
              addUser({ email, role });
              setEmail('');
            }}
            disabled={errors['invite-email'] !== ''}
          />
        </div>
      </div>
      <div className="new-users-list">{renderUserList()}</div>
    </div>
  );
};

export default NewUsers;
