import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../redux/dashboard/actions';
import API from '../../services/api';
import utils from '../../utils';

const PreviewButton = ({ device, onPreview, onError }) => {
  const [loadingPreview, setLoadingPreview] = useState(false);

  const dispatch = useDispatch(setAlert);

  const getPreview = async () => {
    setLoadingPreview(true);
    API.sendCommand(device.companyId, device.id, 'preview')
      .then(res => {
        onPreview(utils.get(res, 'url'));
      })
      .catch(error => {
        onError(error.message);
        dispatch(setAlert(utils.generateAlert(`Something went wrong! ${error.message}`, 'error')));
      })
      .finally(() => {
        setLoadingPreview(false);
      });
  };

  return (
    <button id="preview-button" onClick={getPreview} className="btn btn-secondary" disabled={loadingPreview}>
      {loadingPreview ? (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          <span className="sr-only">Loading...</span>
        </>
      ) : (
        'Preview'
      )}
    </button>
  );
};

export default PreviewButton;
