import React, { useState } from 'react';
import './styles.scss';
import Timezone from '../../../../components/timezone';
import CustomInput from '../../../../components/customInput';
import ActionButtons from '../../../../components/actionButtons';
import icons from '../../../../assets/icons';

const Credential = ({ id, label, values, onRemove, onValueChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  let fields = [Object.entries(values)];
  fields = fields[0];

  return (
    <div className="credential">
      <div className="credential-header">
        <div className="accordion-header">
          <div className="accordion-title" onClick={() => setIsOpen(!isOpen)}>
            <img className="credential-icons" src={icons['userDark']} alt={'Account icon'} />
            <div className="credential-name">{label}</div>
            <img
              className="credential-icons"
              src={isOpen ? icons['angleDown'] : icons['angleRight']}
              alt={'Toggle accordion'}
            />
          </div>
          <div className="action-buttons">
            <ActionButtons
              buttons={[
                {
                  id: `remove-account-${id}`,
                  icon: 'del',
                  key: 'remove-account',
                  tooltip: 'Remove Account',
                  onPress: () => onRemove(id)
                }
              ]}
            />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="credential-body">
          <div className="form-group">
            <div className="form-row">
              {fields.map(field => {
                const label = field[0];
                const value = field[1];
                return (
                  <div className="col" key={field}>
                    <label>{label}</label>
                    {label === 'pop_timezone' ? (
                      <Timezone
                        onSelect={e => {
                          onValueChange(id, field, e.target.value);
                        }}
                      />
                    ) : (
                      <CustomInput
                        id={`new-mappings-${field}`}
                        type="text"
                        value={value}
                        isEditable
                        onChange={e => {
                          onValueChange(id, field, e.target.value);
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Credential;
